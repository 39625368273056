import FileInput from "@/components/Common/Inputs/FileInput/FileInput";
import PagePreloader from "@/components/Components/PagePreloader/PagePreloader.vue";
import Vue3Datatable from '@bhplugin/vue3-datatable'
import '@bhplugin/vue3-datatable/dist/style.css'
import CrossIcon from "@/components/Common/Icons/CrossIcon.vue";
import LoadingCircle from "@/components/Common/Icons/LoadingCircle.vue";

export default {
    name: 'AdminVendorProductsImport',
    components: {
        LoadingCircle,
        CrossIcon,
        PagePreloader,
        FileInput,
        Vue3Datatable
    },
    data() {
        return {
            isLoading: false,
            products: null,
            stats: null,
            cols: [
                { field: "sku", title: "SKU", width: "90px", filter: false },
                { field: "productName", title: "Име" },
                { field: "price", title: "Цена" },
                { field: "promoPrice", title: "Промо цена" },
                { field: "description", title: "Описание", width: "200px"},
                { field: "categories", title: "Категории" },
                { field: "specifications", title: "Спецификации" },
                { field: "images", title: " Изображения" },
                { field: "status", title: " Status" },
            ],
            colsErrors: [
                { field: "line", title: "Ред в TSV", width: "90px", filter: false },
                { field: "error", title: "Грешка" },
            ],
            errors: null,
            processId: null,
            status: null,
            importInterval: null, //hold the setInterval instance
        }
    },
    methods: {
        async uploadFile(event) {
            await this.$store.dispatch('utils/openNotificationModal', {type: 'loading'});
            await this.$store.dispatch('utils/closeModal', 'productImport');
            this.products = null;
            this.errors = null;
            const file = event.target.files[0];
            const formData = new FormData();
            formData.append('file', file, file.name);
            try {
                const response = await this.$store.dispatch('product/importProducts', {file: formData});
                this.products = response.products;
                this.status = parseInt(response.status);
                await this.loadImportProcess();
                await this.$store.dispatch('utils/closeNotificationModal', {type: 'loading'});
            } catch (err) {
                this.errors = err.response.data.errors ?? null;
                await this.$store.dispatch('utils/closeNotificationModal', {type: 'loading'});
                if (!this.errors) {
                    await this.$store.dispatch('utils/openNotificationModal', {
                        type: 'error',
                        message: 'Грешка: ' + err.message
                    });
                }
            }
        },
        async changeStatus(status) {
            await this.$store.dispatch('product/changeImportProcessStatus', {id: this.processId, status: status});
            await this.loadImportProcess();
        },
        async loadImportProcess(showPreloader = true) {
            if (showPreloader) {
                this.isLoading = true;
            }
            try {
                const process = await this.$store.dispatch('product/getImportProcess');
                this.products = null;
                this.errors = null;
                if (process) {
                    this.processId = process.id;
                    this.products = process.products;
                    this.stats = process.stats;
                    this.status = parseInt(process.status);
                }
                this.isLoading = false;
            } catch (err) {
                this.isLoading = false;
            }
        }
    },
    async mounted() {
        if (this.$store.state.user.info.type !== 'vendor') {
            this.$router.push({name: 'NotFoundPage'})
        }
        let vm = this;
        await this.loadImportProcess();
        this.importInterval = setInterval(async () => {
            await vm.loadImportProcess(false);
        }, 5000)

        // const container = this.$refs.productsContainers;
        // const wrapper = this.$refs.inquiryWrapper;
        // container.style.width = wrapper.clientWidth + 'px';
    },
    unmounted() {
        clearInterval(this.importInterval);
    }
}
